import UploadFile from '@/models/UploadFile';

export default {
  props: {
    items: {
      type: Array,
      required: true,
      validator: prop =>
        prop.length > 0 && prop.every(item => item instanceof UploadFile)
    }
  }
};
