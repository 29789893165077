<template>
  <OverlayContainer tag-name="aside" :class="$style['image-gallery-overlay']">
    <div :class="$style['image-gallery-overlay__logo-wrapper']">
      <img
        src="../../../assets/img/main-menu/logo.png"
        alt=""
        :class="$style['image-gallery-overlay__logo']"
      />
    </div>
    <ContentContainer :class="$style['image-gallery-overlay__content']">
      <BaseGallery :items="items">
        <template v-slot:selected="{ item }">
          <div :class="$style['image-gallery-overlay__selected-image-wrapper']">
            <MediaImage
              :src="item.url"
              :strapi="true"
              :class="$style['image-gallery-overlay__selected-image']"
            />
          </div>
        </template>
      </BaseGallery>
    </ContentContainer>
  </OverlayContainer>
</template>

<script>
import OverlayContainer from '@/components/containers/OverlayContainer';
import BaseGallery from '@/components/media/gallery/BaseGallery';
import galleryBaseMixin from '@/components/media/gallery/galleryBaseMixin';
export default {
  name: 'ImageGalleryOverlay',
  components: {
    OverlayContainer,
    BaseGallery
  },
  mixins: [galleryBaseMixin]
};
</script>

<style module>
.image-gallery-overlay {
  @apply bg-black bg-opacity-90;
}

.image-gallery-overlay__logo-wrapper {
  @apply flex justify-center items-center py-4 h-1/8;
}

.image-gallery-overlay__logo {
  @apply w-1/2;
}

.image-gallery-overlay__content {
  @apply w-full h-full py-0 h-7/8;
}

.image-gallery-overlay__selected-image-wrapper {
  @apply w-full h-full flex items-center justify-center;
}

.image-gallery-overlay__selected-image {
  @apply max-w-full max-h-full;
}
</style>
