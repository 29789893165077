<template>
  <div :class="$style['base-gallery']">
    <div :class="$style['base-gallery__selected']">
      <slot name="selected" v-bind:item="selectedItem" />
    </div>
    <ul v-if="items.length > 1" :class="$style['base-gallery__grid']">
      <MediaBgImage
        v-for="item in paginatedItems"
        tag-name="li"
        :key="item.id"
        :class="{
          [$style['base-gallery__grid-item']]: true,
          [$style['base-gallery__grid-item--selected']]:
            item.id === selectedItem.id
        }"
        :src="uniformThumbnail || item.url"
        :strapi="!Boolean(uniformThumbnail)"
        @click.native="selectItem(item)"
      >
        <div :class="$style['base-gallery__grid-item--selected-overlay']" />
      </MediaBgImage>
    </ul>
  </div>
</template>

<script>
import MediaBgImage from '@/components/media/MediaBgImage';
import galleryBaseMixin from '@/components/media/gallery/galleryBaseMixin';

export default {
  name: 'BaseGallery',
  components: {
    MediaBgImage
  },
  mixins: [galleryBaseMixin],
  props: {
    uniformThumbnail: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      selectedItem: this.items[0],
      batchSize: 6,
      page: 0
    };
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    paginate(delta) {
      this.page += delta;
    }
  },
  computed: {
    /**
     * @returns {[UploadFile]}
     */
    paginatedItems() {
      return this.items.slice(this.page * this.batchSize, this.batchSize);
    },
    /**
     * @returns {boolean}
     */
    canPaginateForward() {
      return (this.page + 1) * this.batchSize < this.item.length;
    },
    /**
     * @returns {boolean}
     */
    canPaginateBack() {
      return this.page > 0;
    }
  }
};
</script>

<style module>
.base-gallery {
  @apply h-full w-full flex flex-col justify-center;
}
.base-gallery__selected {
  @apply w-full h-1/2
  bg-black
  pb-4;
}

.base-gallery__grid {
  @apply w-full
  flex justify-start items-center flex-wrap;
}

.base-gallery__grid-item {
  @apply w-1/3 h-24
  cursor-pointer
  bg-center bg-cover;
}

/*.base-gallery__grid-item:nth-child(3n + 3),*/
/*.base-gallery__grid-item:last-child {*/
/*  @apply border-r;*/
/*}*/

.base-gallery__grid-item--selected {
  @apply bg-primary;
}

.base-gallery__grid-item--selected-overlay {
  @apply w-full h-full
  bg-primary
  opacity-0
  transition-opacity duration-300;
}

.base-gallery__grid-item--selected .base-gallery__grid-item--selected-overlay {
  @apply opacity-40;
}
</style>
